<template>
  <div class="mine">
    <div class="banner">
      <div class="item-banner">
        <img :src="avatar" alt="" />
        <div class="info">
          <span>{{name}}</span>
          <span class="phone">{{mobile ? mobile : '请先实名'}}</span>
        </div>
        <div class="renzheng_btn" @click="toAttestation" v-if="is_bind == 0">
          实名认证 >
        </div>
      </div>
    </div>
    <div class="line" @click="toMySuggestion">
      <div class="item-line">
        <div class="line-left">
          <img src="../../assets/images/mine/yijian_icon.png" alt="" />
          我的意见征集
        </div>
        <div class="line-right">
          <img src="../../assets/images/mine/jiantou.png" alt="" />
        </div>
      </div>
    </div>
    <div class="line" @click="toMycollect">
      <div class="item-line">
        <div class="line-left">
          <img src="../../assets/images/mine/minyi_icon.png" alt="" />
          我的民意
        </div>
        <div class="line-right">
          <img src="../../assets/images/mine/jiantou.png" alt="" />
        </div>
      </div>
    </div>
    <div class="line" @click="toMyQes">
      <div class="item-line">
        <div class="line-left">
          <img src="../../assets/images/mine/qes.png" alt="" />
          我的问卷调查
        </div>
        <div class="line-right">
          <img src="../../assets/images/mine/jiantou.png" alt="" />
        </div>
      </div>
    </div>
    <div class="line" @click="toMyElectorate" v-if="is_rd == 1">
      <div class="item-line">
        <div class="line-left">
          <img src="../../assets/images/mine/advice.png" alt="" />
          选民意见
        </div>
        <div class="line-right">
          <img src="../../assets/images/mine/jiantou.png" alt="" />
        </div>
      </div>
    </div>
    <tabber></tabber>
  </div>
</template>

<script>
import tabber from "@/components/tabber/tabber.vue";
export default {
  name: "Mine",
  components: {
    tabber,
  },
  data() {
    return {
      avatar: this.$store.state.user.userInfo.avatar,
      name: this.$store.state.user.userInfo.name,
      is_bind: this.$store.state.user.userInfo.is_bind,
      is_rd: this.$store.state.user.userInfo.is_rd,
      mobile:this.$store.state.user.userInfo.phone
    };
  },
  created() {
  },
  methods: {
    toAttestation() {
        this.$router.push("/attestation");
    },
    toMycollect(){
      this.$router.push("/mySuggest");
    },
    toMyElectorate(){
      this.$router.push("/electorateList");
    },
    toMyQes(){
      this.$router.push("/myQuestionnaireList");
    },
    toVote() {  // 投票评议
      this.$router.push("/myVoteList");
    },
    toMySuggestion(){ // 意见征集
      this.$router.push("myproposal");
    }
  },
};
</script>

<style scoped lang="less">
.mine {
  width: 100%;
  overflow: hidden;
  .banner {
    width: 100%;
    height: 4.22rem;
    background-image: url("../../assets/images/mine/banner1.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding: 0.01rem;
    .item-banner {
      position: relative;
      margin: 0.8rem 0 0 0.4rem;
      color: #fff;
      letter-spacing: 0.02rem;
      img {
        width: 1.33rem;
        height: 1.33rem;
        border-radius: 50%;
        vertical-align: -0.8rem;
        margin-right: 0.4rem;
      }
      .info{
        display: inline-block;
        height: 100%;
        line-height: 100%;
        position: absolute;
        top: 0.3rem;
        span{
          display: block;
        }
        .phone{
          margin-top: 0.2rem;
        }
      }
      .renzheng_btn{
        width: 1.875rem;
        height: .625rem;
        font-size: 0.3rem;
        font-weight: 500;
        position: absolute;
        top: 0.2rem;
        right: 0rem;
        display: inline-block;
        background: #FFB342;
        text-align: center;
        line-height: .625rem;
        border-top-left-radius: 0.3rem;
        border-bottom-left-radius: 0.3rem;
      }
    }
  }
  .line {
    width: 100%;
    height: 1rem;
    padding: 0 0.2rem;
    .item-line {
      width: 100%;
      height: 1rem;
      padding: 0 0.2rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #fff;
      border-radius: 0.1rem;
      margin-top: 0.2rem;
      .line-left {
        line-height: 1rem;
        img {
          width: 0.35rem;
          height: 0.35rem;
          vertical-align: -0.05rem;
          margin-right: 0.2rem;
        }
      }
      .line-right {
        img {
          width: 0.12rem;
          height: 0.22rem;
        }
      }
    }
  }
}
</style>